import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const language = window.localStorage.getItem("language") || "ru";

i18n.use(initReactI18next).init({
  resources: {
    ru: {
      translation: {
        orderForm: {
          "successMessage": "Ваш запрос успешно обработан! В ближайшее время наш менеджер свяжется с вами для уточнения деталей. Спасибо за обращение!"
        },
        navbar: {
          main: "ГЛАВНАЯ",
          aboutCompany: "О КОМПАНИИ",
          partners: "",
          ourAdvantages: "НАШИ ПРЕИМУЩЕСТВА",
          catalog: "КАТАЛОГ",
          vacancies: "ВАКАНСИИ",
          contacts: "КОНТАКТЫ",
        },
        about: {
          misson: "МИССИЯ КОМПАНИИ",
          data_vozmojt:
            "Дать возможность каждому стать тем, кем он хочет, и нести ответственность за выбранный путь.",
          company_nobel_trade:
            "Компания Nobel Trade является крупнейшим импортёром подсолнечного масла, как фасованного, так и наливного, а также пальмового жира и соевого шрота из СНГ и стран Юго-Восточной Азии.",
          nobel_trade: "NOBEL TRADE",
          company_sochet: "компания сочетающая в себе",
          skorost_kachestva: "СКОРОСТЬ И КАЧЕСТВО",
          call_center: "CALL-CENTER",
          sobs: "СОБСТВЕННЫЙ",

          pozovlyat_company:
            "Позволяет компании осуществлять качественный сервисом клиентам и конечным потребителям.",
          our_company: " КОМПАНИИ",
          nashey: "О НАШЕЙ",
          company_nobel:
            "Компания Nobel Trade обеспечивает отличное качество продукции благодаря слаженной работе команды специалистов.",
          company_nobel_log: "   УСЛУГА ЛОГИСТИКИ",
          com: "КОМПАНИЯ",
          logistic_text: "NOBEL LOGISTIC",

          kotoriy_doxot:
            "Мы предлагаем спектр услуг для удовлетворения всех типов ваших потребностей.",
          nobel_logistik:
            "Компания «Nobel Logists » была создана с целью предоставления оптимальных транспортных решений своим клиентам.Для наших клиентов мы предоставляем только качественные и продуманные решения с целью оптимизации вопросов грузоперевозок.",
          uje_bole:
            "Уже более 50 клиентов начали сотрудничество с нами и по сей день они остаются довольны нашими услугами.",
          poprobuy: "Попробуйте и вы !",
          nashi_part:
            "Наши партнёры стараются сотрудничать с нами на постоянной основе для поддержания качественных транспортныхпоставок и оптимизации транспортных маршрутов.",
          mi_je:
            "Мы же в свою очередь пытаемся дать нашим партнёрам лучшие условия сотрудничества!",
          yay: "является предоставление лучших услуг логистики и гарантия лучшего качества этих услуг.",
          uzn: "УЗНАТЬ ПОДРОБНЕЕ",
          ruko: "Руководители КОМПАНИИ",
          ibragimov: "Ибрагимов Ботир Бахрамович",
          sadiqev: "Садыков Иззет Шевкиевич",
          rasul: "Расулов Жахонгир Алимжанович",
          uchi: "Учредитель",
          nasi: "Насиров Санжар Хуснитдинович",
          direc: "Директор",
          sotrudnik: "СОТРУДНИКИ КОМПАНИИ",
          sodiq: "Содиков Абдуллох",
          maslo: "Масло отдел",
          tura: "Тураходжаев Сардор",
          jir: "Жир отдел",
          ergashev: "Эргашев Сардор Шухрат угли",
          suxoy: "Сухое молоко отдел",
          odilov: "Одилов Иброхим Сурьатович",
          krupa: "Крупа отдел",
          yuldashev: "Юлдашев Жамолиддин",
          kraxmal: "Крахмал отдел",
          nashi: "НАШИ ФИЛИАЛЫ",
          osnoviy:
            "Основной актив NOBEL TRADE – это разветвленная дистрибуционная сеть, включающая 12 филиалов с офисами и складами, охватывающих все 12 областей страны. Эта обширная инфраструктура позволяет компании обеспечивать оперативные и своевременные поставки своей продукции по всей территории Узбекистана, эффективно удовлетворяя потребности клиентов.",
          stanov: "СТАНОВИТЕСЬ ПАРТНЕРАМИ",
          raz: "РАЗВИВАЙТЕСЬ ВМЕСТЕ С",
          nomer: "+998 71 209 33 35",
          tash: "г. Ташкент, Кичик Халка Йули 4",
          year: "©2024 Nobel Trade. All rights reserved.",
        },
        vacancy: {
          nobe_tr: "NOBEL TRADE",
          vash_shans: "ВАШ ШАНС НА УСПЕШНУЮ КАРЬЕРУ!",
          nash_uspex: "NOBEL TRADE — Наш успех результат работы нашей высококвалифицированной команды, и сейчас у нас есть возможность расширить эту команду!",
          vaka: "Наши вакансии",
          no_vacancies: "Новые вакансии появятся тут!"
        },
        form: {
          "selectedCategories": "Выбранные категории",
          "oil": "Масло",
          "fat": "Жир",
          "dryMilk": "Сухое молоко",
          "starch": "Крахмал",
          "buckwheatRice": "Гречка, рис",
          "namePlaceholder": "ФИО",
          "phonePlaceholder": "Номер телефона",
          "requiredFields": "Пожалуйста, заполните все обязательные поля.",
          "companyPlaceholder": "Название вашей компании",
          "emailPlaceholder": "E-mail",
          "commentPlaceholder": "Ваш комментарий",
          "submitButton": "Отправить заявку"
        },
        form2: {
          "vacancyType": "Тип вакансии",
          "guard": "Охраник",
          "programmer": "Программист",
          "accountant": "Бухгалтер",
          "financier": "Финансист",
          "namePlaceholder": "ФИО",
          "nameRequired": "Пожалуйста, введите имя",
          "phoneRequired": "Пожалуйста, введите номер телефона",
          "commentRequired": "Пожалуйста, введите комментарий",
          "phonePlaceholder": "Номер телефона",
          "commentPlaceholder": "Ваш комментарий",
          "submitButton": "ОТПРАВИТЬ ЗАЯВКУ"
        },
        modalMenu: {
          "title": "Ваша заявка отправлена"
        },
        home: {
          main: "ГЛАВНАЯ",
          about: "О КОМПАНИИ",
          catalog: "КАТАЛОГ",
          vacancy: "ВАКАНСИИ",
          contact: "КОНТАКТЫ",
          trade: "NOBEL TRADE",
          com_trade: "Компания NOBEL TRADE — лидер пищевой промышленности страны. Уже 20 лет наши бренды знакомы миллионам людей в странах СНГ.",
          for_us: "Для нас звание лидера — это стимул для развития. Мир меняется, и мы меняемся вместе с ним. Теперь мы работаем над новым поколением продуктов питания, меняющих привычные представления о еде, и технологиями, которые позволяют вести экологичный и здоровый образ жизни.",
          food1: "Сухое молоко",
          food2: "Масло",
          food3: "Какао",
          food4: "Крупы",
          food5: "Сыворотки",
          food6: "Крахмал",
          nash_sen: "НАШИ ЦЕННОСТИ",
          guarante: "Гарантия высокого качества продукции",
          solve: "Решение задач клиента",
          big: "Высокий уровень оказываемого сервиса",
          ots: "Отсутствие срыва поставок",
          done: "Выполнение данных обязательств",
          help: "Готовность помочь в трудную минуту",
          partner: "НАШИ ПАРТНЕРЫ",
          dosh: "ДОЧЕРНИЕ КОМПАНИИ",
          stop: "СТАНОВИТЕСЬ ПАРТНЕРАМИ",
          vmeste: "РАЗВИВАЙТЕСЬ ВМЕСТЕ С",
          rights: "©2024 Nobel Trade. Все права защищены."
        },
        sub: {
          maslo: "",
          krax: "",
          kak: "",
          sux: "",
          krup: "",
          jir: ""
        },
        settings: {
          req: "ОТПРАВИТЬ ЗАЯВКУ ",
          pod: "подробно",
          send: "ОТПРАВИТЬ",
          data: "СВОИ ДАННЫЕ",
          brand: "НАШ БРЕНД",
          order: "ОФОРМИТЬ  ",
          order2: "ЗАКАЗ",
          quan: "Количество",
          footerM: 'ВВЕДИТЕ ВАШИ ДАННЫЕ ДЛЯ ОБРАТНОЙ СВЯЗИ'
        }
      },
    },
    en: {
      translation: {
        orderForm: {
          "successMessage": "Your request has been successfully processed! Our manager will contact you shortly to clarify details. Thanks for reaching out!"
        },
        navbar: {
          main: "HOME",
          aboutCompany: "ABOUT COMPANY",
          partners: "",
          ourAdvantages: "OUR ADVANTAGES",
          catalog: "CATALOG",
          vacancies: "VACANCIES",
          contacts: "CONTACTS",
        },
        modalMenu: {
          "title": "Your request has been sent."
        },
        sub: {
          maslo: "",
          krax: "",
          kak: "",
          sux: "",
          krup: "",
          jir: ""
        },
        about: {
          misson: "COMPANY MISSION",
          data_vozmojt: "To give everyone the opportunity to become who they want to be and take responsibility for the chosen path.",
          company_nobel_trade: "Nobel Trade is the largest importer of sunflower oil, both bottled and bulk, as well as palm fat and soybean meal from CIS and Southeast Asian countries.",
          nobel_trade: "NOBEL TRADE",
          company_sochet: "a company combining",
          skorost_kachestva: "SPEED AND QUALITY",
          call_center: " CALL CENTER",
          sobs: "OWN",
          pozovlyat_company: "Allows the company to provide quality service to clients and end consumers.",
          our_company: " COMPANY",
          nashey: "IN OUR",

          company_nobel: "Nobel Trade ensures excellent product quality thanks to the coordinated work of a team of specialists.",
          company_nobel_log: " LOGISTICS SERVICE",
          logistic_text: "NOBEL LOGISTIC",
          com: "COMPANY",
          kotoriy_doxot: "We offer a range of services to meet all types of your needs.",
          nobel_logistik: "Nobel Logists was created to provide optimal transport solutions to its clients. We offer our clients only high-quality and well-thought-out solutions aimed at optimizing cargo transportation.",
          uje_bole: "More than 50 clients have already started working with us, and to this day, they remain satisfied with our services.",
          poprobuy: "Try it yourself!",
          nashi_part: "Our partners aim to cooperate with us on a regular basis to maintain quality transportation deliveries and optimize transport routes.",
          mi_je: "In turn, we strive to offer our partners the best conditions for cooperation!",
          yay: "It is about providing the best logistics services and guaranteeing the best quality of these services.",
          uzn: "LEARN MORE",
          ruko: "NOBEL TRADE Company Executives",
          ibragimov: "Ibragimov Botir Bakhramovich",
          sadiqev: "Sadikov Izzet Shevkievich",
          rasul: "Rasulov Jakhongir Alimzhanovich",
          uchi: "Founder",
          nasi: "Nasirov Sanzhar Khusnitdinovich",
          direc: "Director",
          sotrudnik: "NOBEL TRADE Company Employees",
          sodiq: "Sodikov Abdullah",
          maslo: "Oil Department",
          tura: "Turakhojaev Sardor",
          jir: "Fat Department",
          ergashev: "Ergashev Sardor Shukhrat ugli",
          suxoy: "Powdered Milk Department",
          odilov: "Odilov Ibrokhim Suriyatovich",
          krupa: "Grain Department",
          yuldashev: "Yuldashev Jamoliddin",
          kraxmal: "Starch Department",
          nashi: "OUR BRANCHES",
          osnoviy: "NOBEL TRADE’s main asset is its extensive distribution network, which includes 12 branches with offices and warehouses covering all 12 regions of the country. This extensive infrastructure allows the company to provide prompt and timely delivery of its products across Uzbekistan, effectively meeting customer needs.",
          stanov: "BECOME A PARTNERS",
          raz: "GROW TOGETHER WITH",
          nomer: "+998 71 209 33 35",
          tash: "Tashkent, Kichik Khalka Yuli 4",
          year:
            "©2024 Nobel Trade. All rights reserved.",
        },
        vacancy: {
          nobe_tr: "NOBEL TRADE",
          vash_shans: "YOUR CHANCE FOR A SUCCESSFUL CAREER!",
          nash_uspex: "NOBEL TRADE — Our success is the result of the work of our highly qualified team, and now we have the opportunity to expand this team!",
          vaka: "Our vacancies",
          no_vacancies: "New vacancies appear here!"
        },
        home: {
          main: "HOME",
          about: "ABOUT US",
          catalog: "CATALOG",
          vacancy: "VACANCIES",
          contact: "CONTACTS",
          trade: "NOBEL TRADE",
          com_trade: "NOBEL TRADE is a leader in the country's food industry. For 20 years, our brands have been known to millions of people in the CIS countries.",
          for_us: "For us, the title of leader is a stimulus for development. The world is changing, and we are changing with it. Now, we are working on a new generation of food products that are reshaping traditional views on food and on technologies that enable a sustainable and healthy lifestyle.",
          food1: "Powdered Milk",
          food2: "Butter",
          food3: "Cocoa",
          food4: "Grains",
          food5: "Whey",
          food6: "Starch",
          nash_sen: "OUR VALUES",
          guarante: "Guarantee of high product quality",
          solve: "Client problem-solving",
          big: "High level of service",
          ots: "No disruption of deliveries",
          done: "Fulfillment of obligations",
          help: "Readiness to help in tough times",
          partner: "OUR PARTNERS",
          dosh: "SUBSIDIARIES",
          stop: "BECOME A PARTNER",
          vmeste: "GROW TOGETHER WITH",
          rights: "©2024 Nobel Trade. All rights reserved."
        },
        settings: {
          req: "SEND REQUEST",
          pod: "Detail",
          send: "SEND",
          data: "YOUR DETAILS",
          brand: "OUR BRAND",
          order: "PLACE AN",
          order2: "ORDER",
          quan: "Quantity",
          footerM: 'ENTER YOUR DETAILS FOR FEEDBACK'
        },

        form: {
          "selectedCategories": "Selected categories",
          "oil": "Oil",
          "fat": "Fat",
          "dryMilk": "Dry milk",
          "starch": "Starch",
          "buckwheatRice": "Buckwheat, rice",
          "namePlaceholder": "Full Name",
          "phonePlaceholder": "Phone number",
          "companyPlaceholder": "Company name",
          "requiredFields": "Please fill out all required fields.",
          "emailPlaceholder": "E-mail",
          "commentPlaceholder": "Your comment",
          "submitButton": "Submit application"
        },
        form2: {
          "vacancyType": "Vacancy Type",
          "guard": "Guard",
          "programmer": "Programmer",
          "accountant": "Accountant",
          "financier": "Financier",
          "nameRequired": "Пожалуйста, введите имя",
          "phoneRequired": "Пожалуйста, введите номер телефона",
          "commentRequired": "Пожалуйста, введите комментарий",
          "namePlaceholder": "Full Name",
          "phonePlaceholder": "Phone Number",
          "commentPlaceholder": "Your comment",
          "submitButton": "Submit Application"
        }


      },
    },
    uz: {
      translation: {
        orderForm: {
          "successMessage": "Sizning so'rovingiz muvaffaqiyatli bajarildi! Tafsilotlarni aniqlashtirish uchun menejerimiz tez orada siz bilan bog'lanadi. Murojaat qilganingiz uchun tashakkur!"
        },
        navbar: {
          main: "ASOSIY",
          aboutCompany: "KOMPANIYA HAQIDA",
          partners: "",
          ourAdvantages: "BIZNING AVZIYATLARIMIZ",
          catalog: "KATALOG",
          vacancies: "VAKANSIYALAR",
          contacts: "ALOQA",
        },
        modalMenu: {
          "title": "Sizning arizangiz yuborildi"
        },
        sub: {
          maslo: "",
          krax: "",
          kak: "",
          sux: "",
          krup: "",
          jir: ""
        },

        form: {
          "selectedCategories": "Tanlangan toifalar",
          "oil": "Yog'",
          "fat": "Yog'",
          "dryMilk": "Quruq sut",
          "starch": "Kraxmal",
          "buckwheatRice": "Grechka, guruch",
          "namePlaceholder": "F.I.Sh.",
          "phonePlaceholder": "Telefon raqami",
          "companyPlaceholder": "Kompaniya nomi",
          "requiredFields": "Barcha kerakli maydonlarni toʻldiring.",
          "emailPlaceholder": "E-mail",
          "commentPlaceholder": "Sizning izohingiz",
          "submitButton": "Arizani yuboring"
        },
        form2: {
          "vacancyType": "Vakansiya turi",
          "guard": "Qo'riqchi",
          "programmer": "Dasturchi",
          "accountant": "Buxgalter",
          "financier": "Moliyachi",
          "nameRequired": "Пожалуйста, введите имя",
          "phoneRequired": "Пожалуйста, введите номер телефона",
          "commentRequired": "Пожалуйста, введите комментарий",
          "namePlaceholder": "To'liq ismingiz",
          "phonePlaceholder": "Telefon raqami",
          "commentPlaceholder": "Sizning izohingiz",
          "submitButton": "Arizani yuborish"
        },
        about: {
          misson: "KOMPANIYA MISSIYASI",
          data_vozmojt: "Har bir kishiga kim bo‘lishni istasa, shunday bo‘lish imkonini yaratish va tanlagan yo‘li uchun mas'uliyatni o‘z zimmasiga olish.",
          company_nobel_trade: "Nobel Trade kompaniyasi SNG va Janubi-Sharqiy Osiyo davlatlaridan qadoqlangan va quyma kungaboqar yog‘i, palma yog‘i va soya shrotining eng yirik importchisi hisoblanadi.",
          nobel_trade: "NOBEL TRADE",
          company_sochet: "tezlik va sifatni uyg‘unlashtirgan kompaniya",
          skorost_kachestva: "TEZLIK VA SIFAT",
          call_center: " CALL-MARKAZ",
          sobs: "SHAXSIY",
          pozovlyat_company: "Kompaniya mijozlar va iste'molchilarga sifatli xizmat ko‘rsatishni ta'minlaydi.",
          our_company: " KOMPANIYAMIZ",
          nashey: "BIZNING",
          company_nobel: "Nobel Trade kompaniyasi mutaxassislar jamoasining uyg‘un ishi tufayli mahsulot sifatining a'lo darajada bo‘lishini ta'minlaydi.",
          company_nobel_log: "LOGISTIKA HIZMATI",
          com: "Kompaniyasi",
          logistic_text: "NOBEL LOGISTIC",
          kotoriy_doxot: "Biz sizning barcha turdagi ehtiyojlaringizni qondirish uchun bir qator xizmatlarni taklif etamiz. ",
          nobel_logistik: "Nobel Logists kompaniyasi mijozlariga eng yaxshi transport yechimlarini taklif qilish maqsadida tashkil etilgan. Biz mijozlarimizga faqat yuqori sifatli va yaxshi o‘ylangan yechimlarni taklif qilamiz, bu esa yuk tashish masalalarini optimallashtirishga qaratilgan.",
          uje_bole: "50 dan ortiq mijozlar biz bilan hamkorlikni boshladi va hanuzgacha xizmatlarimizdan mamnun bo‘lib kelmoqda.",
          poprobuy: "Siz ham sinab ko‘ring!",
          nashi_part: "Hamkorlarimiz sifatli transport yetkazib berishni ta’minlash va transport yo‘nalishlarini optimallashtirish uchun biz bilan doimiy ravishda hamkorlik qilishga intilishadi.",
          mi_je: "Biz esa o‘z navbatida hamkorlarimizga eng yaxshi hamkorlik shartlarini taqdim etishga intilamiz!",
          yay: "eng yaxshi logistika xizmatlarini taqdim etish va ularning sifatini kafolatlashni o‘z oldiga maqsad qilgan.",
          uzn: "BATAFSIL",
          ruko: "NOBEL TRADE Kompaniyasi rahbarlari",
          ibragimov: "Ibragimov Botir Bahromovich",
          sadiqev: "Sadikov Izzet Shevkiyevich",
          rasul: "Rasulov Jahongir Alimjonovich",
          uchi: "Tashkilotchi",
          nasi: "Nasirov Sanzhar Husnitdinovich",
          direc: "Direktor",
          sotrudnik: "NOBEL TRADE Kompaniyasi xodimlari",
          sodiq: "Sodikov Abdulloh",
          maslo: "Yog‘ bo‘limi",
          tura: "Turaho‘jayev Sardor",
          jir: "Yog‘ bo‘limi",
          ergashev: "Ergashev Sardor Shuxrat o‘g‘li",
          suxoy: "Quruq sut bo‘limi",
          odilov: "Odilov Ibrohim Suriyatovich",
          krupa: "Krupalar bo‘limi",
          yuldashev: "Yuldashev Jamoliddin",
          kraxmal: "Kraxmal bo‘limi",
          nashi: "BIZNING FILIALLARIMIZ",
          osnoviy: "NOBEL TRADE kompaniyasining asosiy aktivlari 12 viloyatni qamrab olgan 12 ta ofis va omborxonalardan iborat keng tarqalgan distribyutsiya tarmog‘idir. Ushbu keng qamrovli infratuzilma kompaniyaga O‘zbekiston bo‘ylab mahsulotlarni tez va o‘z vaqtida yetkazib berishni ta'minlashga imkon beradi, mijozlar ehtiyojlarini samarali ravishda qondiradi.",
          stanov: "BIZ BILAN HAMKOR BO'LING",
          raz: "BIZ BILAN BIRGA RIVOJLANING",
          nomer: "+998 71 209 33 35",
          tash: "Toshkent Kichik Xalqa Yo‘li 4",
          year: "©2024 Nobel Trade. Barcha huquqlar himoyalangan."
        },
        vacancy: {
          nobe_tr: "NOBEL TRADE",
          vash_shans: "SIZNING MUVAFFAQIYATLI KARYERANGIZ UCHUN IMKONIYATINGIZ!",
          nash_uspex: "NOBEL TRADE — Bizning muvaffaqiyatimiz malakali jamoamiz mehnati natijasidir va hozir bizda ushbu jamoani kengaytirish imkoniyati mavjud!",
          vaka: "Bizning vakansiyalarimiz",
          no_vacancies: "Bu yerda yangi bo'sh ish o'rinlari paydo bo'ladi!"
        },
        home: {
          main: "BOSH SAHIFA",
          about: "KOMPANIYA HAQIDA",
          catalog: "KATALOG",
          vacancy: "BO'SH ISH O'RINLARI",
          contact: "KONTAKTLAR",
          trade: "NOBEL TRADE",
          com_trade: "NOBEL TRADE kompaniyasi mamlakat oziq-ovqat sanoatida yetakchi. 20 yildan beri bizning brendlarimiz MDH davlatlarida millionlab odamlarga tanish.",
          for_us: "Biz uchun yetakchilik unvoni rivojlanish uchun turtki. Dunyo o'zgarmoqda va biz ham u bilan birga o'zgaryapmiz. Endi biz oziq-ovqat mahsulotlarining yangi avlodini ekologik va sog'lom turmush tarzini ta'minlaydigan texnologiyalar ustida ishlamoqdamiz.",
          food1: "Quritilgan sut",
          food2: "Yog",
          food3: "Kakao",
          food4: "Don mahsulotlari",
          food5: "Zardoblar",
          food6: "Kraxmal",
          nash_sen: "BIZNING QADRIYATLARIMIZ",
          guarante: "Mahsulotlarning yuqori sifatiga kafolat",
          solve: "Mijozning muammolarini hal qilish",
          big: "Ko'rsatiladigan xizmatning yuqori darajasi",
          ots: "Yetkazib berishdagi uzilishlarning yo'qligi",
          done: "Berilgan majburiyatlarni bajarish",
          help: "Og'ir paytda yordamga tayyorlik",
          partner: "BIZNING HAMKORLARIMIZ",
          dosh: "SHO'BA KOMPANIYALAR",
          stop: "BIZ BILAN HAMKOR BO'LING",
          vmeste: "BIZ BILAN BIRGA RIVOJLANING",
          rights: "©2024 Nobel Trade. Barcha huquqlar himoyalangan."
        },
        settings: {
          req: "MUROJAATINGIZNI YUBORING",
          pod: "Batafsil",
          send: "MALUMOTLARINGIZNI",
          data: "YUBORING",
          brand: "BIZNING BRENDIMIZ",
          order: "BUYURTMA",
          order2: "BERING",
          quan: "Miqdori",
          footerM: "MULAKABAT UCHUN TAFSILATLARNI KIRITISH"
        }

      },
    },
  },
  lng: language,
  fallbackLng: language,

  interpolation: {
    escapeValue: false,
  },
});
