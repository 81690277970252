import React, { useEffect, useState } from 'react';
import '../applicationModal/applicationModal.scss';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import './aboutModal.scss';
import '../applicationModal/applicationModal.scss';
import exitBtn from '../../../images/exit-btn.svg';
import $ from 'jquery';
import axios from 'axios';
import { toastError, toastSuccess } from '../../../utils/toastAlert';
import { useTranslation } from 'react-i18next';

const AboutModal = ({ modalActive, setModalActive }) => {
  const closeModal = () => {
    setModalActive(false);

    document.body.style.overflow = 'auto';
  };
  const { t } = useTranslation();
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    desc: '',
    radio_button1: '',
    radio_button2: '',
    radio_button3: '',
  });

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { fname, lname, desc, radio_button1, radio_button2, radio_button3 } =
      formData;
    const token = '6870733564:AAH4GKZRjTXUQZ1-ndFry1R-I2SBMEjkcdI';
    const chat_id = '-1002108902387';

    const txt = `<b>ФИО:</b> ${fname}\n<b>Номер Телефона:</b> ${lname}\n<b>Коментарий:</b> ${desc}\n<b>Профессия:</b> ${radio_button1} ${radio_button2} ${radio_button3}`;

    axios
      .post(`https://api.telegram.org/bot${token}/sendMessage`, {
        chat_id: chat_id,
        text: txt,
        parse_mode: 'HTML',
      })
      .then(response => {
        toastSuccess(t('modalMenu.title'));
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(error => {
        console.error('Error sending message:', error);
        toastError(error.message);
      });
  };
  const handleCheckboxChange = event => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedCategories(prevState => [...prevState, value]);
    } else {
      setSelectedCategories(prevState =>
        prevState.filter(category => category !== value)
      );
    }
  };

  return (
    <>
      <div
        className={modalActive ? 'active modal' : 'modal'}
        onClick={() => closeModal()}
      >
        <div
          className="modal-dialog nodal-info"
          onClick={e => e.stopPropagation()}
        >
          <div className="modal-content modal-height">
            <div className="modal-body">
              <h5 className={`form-modal-title`}>{t('settings.footerM')}</h5>
              <img
                className="exit-btn"
                src={exitBtn}
                alt=""
                onClick={() => closeModal()}
              />
              <form
                className={'form-about'}
                action=""
                id="formID"
                onSubmit={handleSubmit}
              >
                <Accordion className={'accordion-position'}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    {t('form2.vacancyType')}: {selectedCategories.join(', ')}
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className="list-group">
                      <li className="list-group-item">
                        <input
                          className="form-check-input me-1"
                          type="checkbox"
                          value={t('form2.guard')}
                          id="firstCheckbox"
                          name="radio_button1"
                          onChange={event => {
                            handleCheckboxChange(event);
                            handleChange(event);
                          }}
                          checked={selectedCategories.includes(
                            t('form2.guard')
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="firstCheckbox"
                        >
                          {t('form2.guard')}
                        </label>
                      </li>
                      <li className="list-group-item">
                        <input
                          className="form-check-input me-1"
                          type="checkbox"
                          value={t('form2.programmer')}
                          id="secondCheckbox"
                          name="radio_button2"
                          onChange={event => {
                            handleCheckboxChange(event);
                            handleChange(event);
                          }}
                          checked={selectedCategories.includes(
                            t('form2.programmer')
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="secondCheckbox"
                        >
                          {t('form2.programmer')}
                        </label>
                      </li>
                      <li className="list-group-item">
                        <input
                          className="form-check-input me-1"
                          type="checkbox"
                          value={t('form2.accountant')}
                          id="thirdCheckbox"
                          name="radio_button3"
                          onChange={event => {
                            handleCheckboxChange(event);
                            handleChange(event);
                          }}
                          checked={selectedCategories.includes(
                            t('form2.accountant')
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="thirdCheckbox"
                        >
                          {t('form2.accountant')}
                        </label>
                      </li>
                      <li className="list-group-item">
                        <input
                          className="form-check-input me-1"
                          type="checkbox"
                          value={t('form2.financier')}
                          id="fourthCheckbox"
                          name="radio_button4"
                          onChange={event => {
                            handleCheckboxChange(event);
                            handleChange(event);
                          }}
                          checked={selectedCategories.includes(
                            t('form2.financier')
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="fourthCheckbox"
                        >
                          {t('form2.financier')}
                        </label>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>

                <input
                  className={`modal-form-info modal-absolute form-control`}
                  type="text"
                  placeholder={t('form2.namePlaceholder')}
                  id="fname"
                  name="fname"
                  value={formData.fname}
                  onChange={handleChange}
                  required
                />
                <input
                  className={`modal-form-phoneNumber modal-form-info form-control`}
                  type="number"
                  placeholder={t('form2.phonePlaceholder')}
                  id="lname"
                  name="lname"
                  value={formData.lname}
                  onChange={handleChange}
                  required
                />
                <textarea
                  className={`modal-form-textarea form-control`}
                  cols="30"
                  rows="10"
                  placeholder={t('form2.commentPlaceholder')}
                  id="desc"
                  name="desc"
                  value={formData.desc}
                  onChange={handleChange}
                  required
                ></textarea>
                <div className="d-grid">
                  <button
                    className={`modal-form-btn btn`}
                    type="submit"
                    value="Submit"
                  >
                    {t('form2.submitButton')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutModal;
